<template>
  <!-- 巡检路线 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
      @submit.native.prevent>
      <el-form-item label="巡检路线：">
        <el-input maxlength="30" v-model="queryForm.route_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.route_name = validSpace(e))" placeholder="请输入巡检路线"></el-input>
      </el-form-item>
      <el-form-item label="资产项目：">
        <el-input maxlength="30" v-model="queryForm.project_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.project_name = validSpace(e))" placeholder="请输入资产项目"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="巡检路线">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('b4ac063befd34e239640aa61')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="handleInspectionRoute('new')"
            v-if='this.getUserInfo.user_name != "admin" && isShowBtn("68e82e4d79f24d878a7c1149")'>新增</lbButton>
        </div>
      </template>
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="handleInspectionRoute(row.Id)"
              v-if="isShowBtn('795c23c15fa349749c038437')"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="route_name" label="巡检路线"></el-table-column>
          <el-table-column prop="project_name" label="资产项目"></el-table-column>
          <el-table-column prop="configCount" label="巡检项"></el-table-column>
          <el-table-column label="创建时间" width="200">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handleInspectionRoute(scope.row.Id)"
                v-if="isShowBtn('795c23c15fa349749c038437')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增编辑弹框 -->
    <lebo-dialog :title="dialogInspectionRouteId === 'new' ? '新增巡检路线' : '编辑巡检路线'" :isShow="showInspectionRoute"
      @close="showInspectionRoute = false" footerSlot>
      <adddInspectionRoute v-if="showInspectionRoute" :id="dialogInspectionRouteId" @closeDialog="closeInspectionRoute">
      </adddInspectionRoute>
    </lebo-dialog>
  </div>
</template>
<script>
import { getInspectionRouteList, delInspectionRoute } from '@/api/assetsManagement'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import adddInspectionRoute from './components/addInspectionRoute/index.vue'
export default {
  components: { adddInspectionRoute },
  data () {
    return {
      queryForm: {
        route_name: '',
        project_name: '',
        PageIndex: 1,
        PageSize: 10
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      showInspectionRoute: false, // 新增编辑弹框
      dialogInspectionRouteId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '巡检路线',
            prop: 'route_name'
          }, {
            label: '资产项目',
            prop: 'project_name'
          }, {
            label: '巡检项',
            prop: 'configCount'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetInspectionRouteList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getUserInfo'])
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取巡检路线
    async fnGetInspectionRouteList () {
      const res = await getInspectionRouteList(this.queryForm)
      // console.log('获取巡检路线---', res);
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetInspectionRouteList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectionRouteList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetInspectionRouteList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectionRouteList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetInspectionRouteList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的巡检路线，您确定要删除选中的巡检路线吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i].Id)
            if (arr.length === this.multipleSelection.length) {
              this.fnDelInspectionRoute(arr.join(','))
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的巡检路线！')
      }
    },
    // 删除巡检路线请求
    async fnDelInspectionRoute (ids) {
      const res = await delInspectionRoute({
        Id: ids
      })
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetInspectionRouteList()
    },
    // 新增编辑巡检路线
    handleInspectionRoute (id) {
      this.showInspectionRoute = true
      this.dialogInspectionRouteId = id
    },
    closeInspectionRoute () {
      this.showInspectionRoute = false
      this.fnGetInspectionRouteList()
    }
  }
}
</script>

<style scoped lang="less"></style>
