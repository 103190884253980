/**
 * 资产模块相关请求
 */
import axios from './http';
// 获取资产项目列表
export const getAssetsProjectList = (params) => axios.get('/AssetManageApi/AssetProject/GetPageList', { params: params })
// 资产部分，获取全部集团的下拉列表
export const getAllGropSelectList = (params) => axios.get('/AssetManageApi/AssetProjectCategory/GetAllGropList', { params: params })
// 新增资产项目
export const addAssetsProject = (data) => axios.post('/AssetManageApi/AssetProject/Add', data)
// 编辑资产项目
export const updateAssetsProject = (data) => axios.post('/AssetManageApi/AssetProject/Update', data)
// 删除资产项目
export const delAssetsProject = (data) => axios.post('/AssetManageApi/AssetProject/Delete', data)
// 资产类别树控件列表
export const getAssetCategoryTreeList = (params) => axios.get('/AssetManageApi/AssetProjectCategory/GetAssetTreeList', { params: params })
// 资产项目类别新增
export const addAssetCategory = (data) => axios.post('/AssetManageApi/AssetProjectCategory/Add', data)
// 资产项目类别编辑
export const updateAssetCategory = (data) => axios.post('/AssetManageApi/AssetProjectCategory/Update', data)
// 资产项目类别删除
export const delAssetCategory = (data) => axios.post('/AssetManageApi/AssetProjectCategory/Delete', data)
// 从已配置中导入配置数据
export const importConfiguration = (data) => axios.post('/AssetManageApi/AssetProjectCategory/ImportConfiguration', data)
// 巡检项新增
export const addAssetInspectionItem = (data) => axios.post('/AssetManageApi/AssetInspection/Add', data)
// 巡检项编辑
export const updateAssetInspectionItem = (data) => axios.post('/AssetManageApi/AssetInspection/Update', data)
// 巡检项删除
export const delAssetInspectionItem = (data) => axios.post('/AssetManageApi/AssetInspection/Delete', data)
// 巡检项列表查询
export const getAssetInspectionItemList = (params) => axios.get('/AssetManageApi/AssetInspection/GetInspectionList', { params: params })
// 巡检项模板列表查询
export const getTemplatePageList = (params) => axios.get('/AssetManageApi/AssetInspection/GetTemplatePageList', { params: params })
// 巡检项模板中导入
export const importInspectionTemplate = (data) => axios.post('/AssetManageApi/AssetInspection/ImportInspectionTemplate', data)
// 巡检项模板删除
export const delInspectionTemplate = (data) => axios.post('/AssetManageApi/AssetInspection/DeleteTemplate', data)
// 新增资产巡检项模板
export const addInspectionTemplate = (data) => axios.post('/AssetManageApi/AssetInspection/AddInspectionTemplate', data)
// 巡检路线列表查询
export const getInspectionRouteList = (params) => axios.get('/AssetManageApi/AssetInspectionRoute/GetPageList', { params: params })
// 巡检路线删除
export const delInspectionRoute = (data) => axios.post('/AssetManageApi/AssetInspectionRoute/Delete', data)
// 获取资产项目下拉列
export const getAssetProjectSelectList = (data = {}) => {
    // console.log(data);
    return axios({
        method: 'post',
        url: '/AssetManageApi/AssetProject/GetAssetProjectList',
        data: data
    })
}
// 获取配置巡检项树控件接口
export const getAssetTemplateTreeList = (params) => axios.get('/AssetManageApi/AssetProjectCategory/GetAssetTreeList', { params: params })
// 巡检路线新增
export const addInspectionRoute = (data) => axios.post('/AssetManageApi/AssetInspectionRoute/Add', data)
// 巡检路线修改
export const updateInspectionRoute = (data) => axios.post('/AssetManageApi/AssetInspectionRoute/Update', data)
// 巡检路线详情
export const getInspectionRouteDetail = (params) => axios.get('/AssetManageApi/AssetInspectionRoute/Detail', { params: params })
// 巡检路线下拉列表获取
export const getInspectionRouteSelectList = (params) => axios.get('/AssetManageApi/AssetInspectionRoute/GetAssetRouteListByProjectId', { params: params })
// 巡检计划列表查询
export const getInspectionPlanList = (params) => axios.get('/AssetManageApi/AssetInspectionPlan/GetPageList', { params: params })
// 巡检计划新增
export const addInspectionPlan = (data) => axios.post('/AssetManageApi/AssetInspectionPlan/Add', data)
// 巡检计划编辑
export const updateInspectionPlan = (data) => axios.post('/AssetManageApi/AssetInspectionPlan/Update', data)
// 巡检计划删除
export const delInspectionPlan = (data) => axios.post('/AssetManageApi/AssetInspectionPlan/Delete', data)
// 巡检人员下拉列表获取
export const getInspectorSelectList = (params) => axios.get('/AssetManageApi/AssetInspector/GetInspectorList', { params: params })
// 编辑时巡检计划详情查看
export const getInspectorPlanUpdateDetail = (params) => axios.get('/AssetManageApi/AssetInspectionPlan/UpdateDetail', { params: params })
// 巡检计划终止或开启
export const setInspectionPlanStatus = (data) => axios.post('/AssetManageApi/AssetInspectionPlan/InspectionPlanSet', data)
// 查看巡检计划详情
export const getInspectorPlanDetail = (params) => axios.get('/AssetManageApi/AssetInspectionPlan/Detail', { params: params })
// 巡检任务列表分页查看
export const getInspectionTaskList = (params) => axios.get('/AssetManageApi/AssetInspectionTask/GetPageList', { params: params })
// 巡检任务详情查看
export const getInspectionTaskDetail = (params) => axios.get('/AssetManageApi/AssetInspectionTask/Detail', { params: params })
// 巡检任务指派功能
export const setAppointInspectionTask = (data) => axios.post('/AssetManageApi/AssetInspectionTask/InspectionTaskAppointSet', data)
// 巡检任务删除
export const delInspectionTask = (data) => axios.post('/AssetManageApi/AssetInspectionTask/Delete', data)
// 异常巡检任务记录列表
export const getTaskRecordList = (params) => axios.get('/AssetManageApi/AssetInspectionException/GetPageList', { params: params })
// 巡检项异常记录删除
export const delTaskRecord = (data) => axios.post('/AssetManageApi/AssetInspectionException/Delete', data)
// 获取巡检任务异常默认指派记录
export const getDefaultAppointList = (params) => axios.get('/AssetManageApi/AssetInspectionException/GetDefaultAppointList', { params: params })
// 默认指派巡检任务记录
export const setDefaultAppoint = (data) => axios.post('/AssetManageApi/AssetInspectionException/DefaultAppoint', data)
// 指派巡检任务记录
export const setAppointOperate = (data) => axios.post('/AssetManageApi/AssetInspectionException/AppointOperate', data)
// 异常巡检任务记录详情
export const getTaskRecordDetail = (params) => axios.get('/AssetManageApi/AssetInspectionException/Detail', { params: params })
// 获取异常指派记录
export const getExceptionAppointList = (params) => axios.get('/AssetManageApi/AssetEventReport/GetExceptionAppoint', { params: params })
// 事件上报记录列表
export const getEventRecordList = (params) => axios.get('/AssetManageApi/AssetEventReport/GetPageList', { params: params })
// 默认指派 事件上报记录
export const setDefaultAppointEventRecord = (data) => axios.post('/AssetManageApi/AssetEventReport/DefaultAppointVideoOrEvent', data)
// 指派 事件上报记录
export const setAppointEventRecord = (data) => axios.post('/AssetManageApi/AssetEventReport/AppointOperate', data)
// 事件上报记录详情
export const getEventRecordDetail = (params) => axios.get('/AssetManageApi/AssetEventReport/Detail', { params: params })
// 事件上报记录删除
export const delEventRecord = (data) => axios.post('/AssetManageApi/AssetEventReport/Delete', data)
// 获取默认异常指派
export const getDefaultExceptionAppoint = (params) => axios.get('/AssetManageApi/AssetEventReport/GetDefaultExceptionAppoint', { params: params })
// 待办工单列表查询
export const getBacklogOrderList = (params) => axios.get('/AssetManageApi/AssetInspectionBacklog/GetPageList', { params: params })
// 待办工单删除
export const delBacklogOrder = (data) => axios.post('/AssetManageApi/AssetInspectionBacklog/Delete', data)
// 待办工单详情
export const getBacklogOrderDetail = (params) => axios.get('/AssetManageApi/AssetInspectionBacklog/Detail', { params: params })
