<template>
    <!-- 新增、编辑巡检路线 -->
    <div>
        <div class="box-card" style="text-align: left">
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
                <div class="title">基本信息</div>
                <el-divider></el-divider>
                <el-form-item label="路线名称：" prop="route_name">
                    <el-input v-model="addForm.route_name" maxlength="30" placeholder="请输入路线名称"
                        @input="(e) => (addForm.route_name = validSpace(e))"></el-input>
                </el-form-item>
                <el-form-item label="资产项目：" prop="project_id">
                    <el-select popper-class="my-select" :disabled="title === '编辑路线'" v-model="addForm.project_id"
                        placeholder="请选择资产项目" @change="addForm.list_inspection = []" filterable>
                        <el-option v-for="item in assetProjectSelectList" :key="item.Id" :label="item.project_name"
                            :value="item.Id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="巡检顺序：" prop="inspection_order">
                    <el-select popper-class="my-select" v-model="addForm.inspection_order" placeholder="请选择巡检顺序">
                        <el-option v-for="item in getOptions('InspectionOrder')" :key="item.value" :label="item.name"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <div class="title">巡检项</div>
                <el-divider></el-divider>
                <el-button class="data_form_item" style="background: #4263f4; color: #fff" size="small"
                    @click="openConfigDialog" icon="iconfont lebo-peizhi">配置巡检项</el-button>
                <el-form-item prop="list_inspection" class="data_form_item">
                    <el-table class="tablestyle" ref="configTable" :data="addForm.list_inspection" row-key="Id"
                        tooltip-effect="dark" height="300" border stripe>
                        <el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center"
                            header-align="center"></el-table-column>
                        <el-table-column prop="inspection_name" label="巡检项名称" min-width="120"></el-table-column>
                        <el-table-column prop="inspection_content" label="检查内容" min-width="280">
                            <template slot-scope="scope">
                                <div>
                                    <span v-html="scope.row.inspection_content.replace(
                                            /\r\n|\n|\r/g,
                                            '<br/>'
                                        )
                                        "></span>
                                    <el-link type="primary" v-if="scope.row.pic.length > 0"
                                        @click="showPreviewPicture(scope.row.pic)">【查看】</el-link>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <lbButton type="err" icon="quxiaopeizhi" hint="取消配置" @click="cancelConfig(scope.row)">
                                </lbButton>
                                <lbButton type="warning" icon="tuodongpaixu" hint="拖动排序"
                                    v-if="addForm.inspection_order == 2" @click="setSort(scope.row)" style="cursor: move">
                                </lbButton>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
            <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm"
            v-preventReClick>保 存</lbButton>
        </div>
        <lebo-dialog  title="配置巡检项" :isShow="isConfigDialog" width="65%"
            @close="configDialogClose" footerSlot>
            <div class="content_box">
                <div class="left_box">
                    <el-tree :data="treeData" accordion node-key="id" :props="customProps" ref="treeDataRef"
                        :expand-on-click-node="false" @node-click="categoryTreeClick" default-expand-all>
                        <span class="custom-tree-node" slot-scope="{ node, data }">
                            <span :style="data.id == queryForm.bind_id ? 'color:#409EFF;' : ''">{{ node.label }}</span>
                            <!-- {{data.id}} -->
                            <span v-if="data.selectTotal">（{{ data.selectTotal }}）</span>
                        </span>
                    </el-tree>
                </div>
                <div class="vertical_box">
                    <el-divider direction="vertical"></el-divider>
                </div>
                <div class="right_box">
                    <div class="hearder">
                        <div style="display: flex; height: inherit">
                            <el-input size="small" v-model="queryForm.inspection_name" placeholder="请输入巡检项名称"></el-input>
                            <el-button size="small" type="primary" style="margin-left: 15px"
                                @click="onSubminSearch">查询</el-button>
                        </div>
                        <div>已选择{{ allSelectTotal }}个</div>
                    </div>
                    <el-table class="tablestyle" :data="noConfigData" ref="noConfigDataRef" tooltip-effect="dark" border
                        stripe @select="selectionRow" @select-all="selectAll">
                        <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
                        <el-table-column prop="inspection_name" label="巡检项名称" min-width="40"></el-table-column>
                        <el-table-column label="巡检项内容">
                            <template slot-scope="scope">
                                <div>
                                    <span v-html="scope.row.inspection_content.replace(
                                            /\r\n|\n|\r/g,
                                            '<br/>'
                                        )
                                        "></span>
                                    <el-link type="primary" v-if="scope.row.pic.length > 0"
                                        @click="showPreviewPicture(scope.row.pic)">【查看】</el-link>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <lbButton type="goBack" icon="back" @click="isConfigDialog = false">返 回</lbButton> -->
                <lbButton type="default" fill icon="confirm" @click="Save()">保 存</lbButton>
            </span>
        </lebo-dialog>
        <!-- 图片预览 -->
        <el-image-viewer v-if="isShowPreviewPicture" :on-close="onClosePreviewPicture" :url-list="previewPictureList"
            :z-index="9999" />
    </div>
</template>

<script>
import Sortable from 'sortablejs'
import axios from 'axios'
import {
  addInspectionRoute,
  getAssetInspectionItemList,
  updateInspectionRoute,
  getNotConfigInspectionList,
  getAssetProjectSelectList,
  getAssetTemplateTreeList,
  getInspectionList,
  getInspectionRouteDetail
} from '@/api/assetsManagement'
import { mapMutations, mapGetters } from 'vuex'
// // 导入组件
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  props: ['id'],
  data () {
    return {
      title: '',
      addForm: {
        route_name: '',
        project_id: '',
        inspection_order: 2,
        list_inspection: []
      },
      addFormRules: {
        route_name: [
          { required: true, message: '请输入路线名称', trigger: 'blur' }
        ],
        project_id: [
          { required: true, message: '请选择资产项目', trigger: 'blur' }
        ],
        inspection_order: [
          { required: true, message: '请选择巡检顺序', trigger: 'blur' }
        ],
        list_inspection: [
          {
            type: 'array',
            required: true,
            message: '请配置巡检项',
            trigger: 'blur'
          }
        ]
      },
      // 资产项目下拉列
      assetProjectSelectList: [],
      // 是否展示配置巡检项对话框
      isConfigDialog: false,
      isConfigLoading: false,
      // 查询待配置巡检项的数据
      queryForm: {
        bind_id: '',
        inspection_name: ''
      },
      // 待配置巡检项列表
      noConfigData: [],
      // 树控件列表
      treeData: [],
      customProps: {
        label: 'name',
        children: 'children'
      },
      // 选中的巡检项
      selectInspectionItemList: [],
      allSelectTotal: 0,
      el: null,
      // 展示图片预览
      isShowPreviewPicture: false,
      // 预览图片
      previewPictureList: []
    }
  },
  created () {
    // console.log(this.id);
    this.fnGetAssetProjectSelectList()
    if (this.id === 'new') {
      this.title = '新增路线'
    } else {
      this.title = '编辑路线'
      this.fnGetInspectionRouteDetail()
    }
  },
  watch: {
    treeData (newVal, oldVal) {
      if (newVal) {
        this.allSelectTotal = 0
        // 根据树结构，统计选中的总数量
        this.getSelectAllTotal(this.treeData, 0)
      }
    }
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
    this.el = this.$refs.configTable.$el.querySelectorAll(
      '.el-table__body-wrapper > table > tbody'
    )[0]
    this.setSort()
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 获取巡检路线详情
    async fnGetInspectionRouteDetail () {
      const res = await getInspectionRouteDetail({
        Id: this.id
      })
      console.log('获取巡检路线详情----', res.Data.routeDetail[0].project_id)
      if (res && res.Code === 200) {
        this.addForm.list_inspection = res.Data.listInspection
        this.addForm.route_name = res.Data.routeDetail[0].route_name
        this.addForm.project_id = res.Data.routeDetail[0].project_id
        this.addForm.inspection_order =
                    res.Data.routeDetail[0].inspection_order
      }
    },
    // 获取资产项目下拉列
    async fnGetAssetProjectSelectList () {
      const res = await getAssetProjectSelectList()
      // console.log('获取资产项目下拉列---', res);
      this.assetProjectSelectList = res && res.Code === 200 ? res.Data : []
      this.addForm.project_id =
                this.assetProjectSelectList.length > 0 ? this.addForm.project_id : ''
    },
    // 打开配置巡检项对话框
    openConfigDialog () {
      if (this.addForm.project_id) {
        // 当前绑定的节点为资产项目节点
        this.queryForm.bind_id = this.addForm.project_id
        this.isConfigLoading = true
        var that = this
        axios
          .all([this.fnGetAssetTemplateTreeList(), this.fnGetInspectionList()])
          .then(
            axios.spread(function (tree, inspection) {
              // console.log('获取树控件接口------', tree);
              that.treeData = tree && tree.Code === 200 ? tree.Data : []
              // console.log('获取巡检项列表接口******', inspection);
              that.noConfigData =
                                inspection && inspection.Code === 200 ? inspection.Data : []
              // 判断巡检项配置列表是否有数据
              if (that.addForm.list_inspection.length > 0) {
                // 遍历巡检项配置列表
                var list = that.addForm.list_inspection.concat()
                that.addForm.list_inspection.forEach((data) => {
                  // 添加节点选中巡检项数量
                  that.treeData = that.getSelectTotal(
                    that.treeData.concat(),
                    data.bind_id,
                    1,
                    true
                  )
                  // 判断选中巡检项列表中是否有数据
                  if (that.selectInspectionItemList.length > 0) {
                    // 若有数据，则筛选出属于当前配置项节点的数据
                    var arr = that.selectInspectionItemList.filter((item) => {
                      return item.id === data.bind_id
                    })
                    // 若筛选出的有数据
                    if (arr.length > 0) {
                      // 则遍历选中巡检项列表，将当前配置项节点的数据添加到对应的节点下
                      that.selectInspectionItemList.forEach((item) => {
                        if (item.id === data.bind_id) {
                          item.list.push(data)
                        }
                      })
                    } else {
                      // 若没有筛选出数据，则直接添加
                      that.selectInspectionItemList.push({
                        id: data.bind_id,
                        list: [data]
                      })
                    }
                  } else {
                    // 若没有数据，则直接添加进去
                    that.selectInspectionItemList.push({
                      id: data.bind_id,
                      list: [data]
                    })
                  }
                })
                // console.log('openConfigDialog---11111111--', that.selectInspectionItemList);
                // 若有数据，则遍历获取的巡检项
                that.noConfigData.forEach((item) => {
                  // 遍历巡检项配置列表
                  that.addForm.list_inspection.forEach((data) => {
                    // 判断配置列表id 与巡检项id是否相等，若相等，则设置其选中状态
                    if (item.Id === data.Id) {
                      that.$nextTick(() => {
                        that.$refs.noConfigDataRef.toggleRowSelection(
                          item,
                          true
                        )
                      })
                    }
                  })
                })
                console.log(
                  'openConfigDialog-----',
                  that.selectInspectionItemList
                )
              }
            })
          )
        this.isConfigDialog = true
        this.isConfigLoading = false
      } else {
        this.$msg.info('请先选择资产项目')
      }
    },
    // 统计选中的总数量
    getSelectAllTotal (data) {
      data.forEach((item) => {
        if (item.selectTotal) {
          this.allSelectTotal = this.allSelectTotal + item.selectTotal
        }
        if (item.children && item.children.length > 0) {
          this.getSelectAllTotal(item.children, this.allSelectTotal)
        }
      })
    },
    // 获取配置树控件接口
    fnGetAssetTemplateTreeList () {
      return getAssetTemplateTreeList({
        project_id: this.addForm.project_id
      })
    },
    // 获取巡检项列表接口
    fnGetInspectionList () {
      return getAssetInspectionItemList(this.queryForm)
    },
    // 点击树控件
    categoryTreeClick (data, node, key) {
      // console.log('data---点击树控件------', data);
      // console.log('node---点击树控件------', node);
      // console.log('key---点击树控件------', key);
      // 获取巡检项列表
      var that = this
      this.queryForm.bind_id = data.id
      axios.all([this.fnGetInspectionList()]).then(
        axios.spread(function (inspection) {
          // console.log('获取巡检项列表接口******', inspection);
          that.noConfigData =
                        inspection && inspection.Code === 200 ? inspection.Data : []
          // 判断选中的巡检项列表中是否有数据
          if (that.selectInspectionItemList.length > 0) {
            // 若存在数据
            // 1、遍历巡检项列表
            // 2、遍历选中的巡检项列表
            // 3、判断两者id是否相等，若相等，则选中
            that.noConfigData.forEach((item) => {
              that.selectInspectionItemList.forEach((data) => {
                if (data.id === that.queryForm.bind_id) {
                  data.list.forEach((li) => {
                    if (item.Id === li.Id) {
                      that.$nextTick(() => {
                        that.$refs.noConfigDataRef.toggleRowSelection(
                          item,
                          true
                        )
                      })
                    }
                  })
                }
              })
            })
          }
        })
      )
    },
    selectionRow (selection, row) {
      // console.log('selectionRow---', selection);
      // console.log('row---', row);
      var arr = this.treeData.concat()
      var index = -1
      // 判断选中的巡检项列表是否有数据，若有，则获取当前节点所在索引位置
      if (this.selectInspectionItemList.length > 0) {
        index = this.selectInspectionItemList.indexOf(
          this.selectInspectionItemList.filter((item) => {
            return item.id === this.queryForm.bind_id
          })[0],
          0
        )
      }
      if (selection.length > 0) {
        // 表格中有选中项
        if (index === -1) {
          // 选中的巡检项列表中没有当前节点数据
          this.selectInspectionItemList.push({
            id: this.queryForm.bind_id,
            list: selection
          })
          // 统计选中的数量
          this.treeData = this.getSelectTotal(
            arr,
            this.queryForm.bind_id,
            selection.length,
            false
          )
        } else {
          // 判断当前项是否是选中的
          var rowIndex = selection.indexOf(row, 0)
          if (rowIndex === -1) {
            // 当前项没有选中
            // 1、遍历当前索引下的list巡检项，
            this.selectInspectionItemList[index].list =
                            this.selectInspectionItemList[index].list.filter((item) => {
                              return row.Id !== item.Id
                            })
          } else {
            // 当前项是选中的
            // 1、遍历当前索引下的list巡检项，
            // 2、筛选出与list中id相等的数据，保存不相等的数据（若相等，则表示已存在，不添加；）
            // 3、将不相等的数据，添加到选中巡检项列表中
            this.selectInspectionItemList[index].list.forEach((item) => {
              // console.log(item);
              selection = selection.filter((data) => {
                return item.Id !== data.Id
              })
            })
            this.selectInspectionItemList[index].list.push(...selection)
          }
          // 统计选中的数量
          this.treeData = this.getSelectTotal(
            arr,
            this.queryForm.bind_id,
            this.selectInspectionItemList[index].list.length,
            false
          )
        }
      } else {
        // 没有任何选中项
        // 1、遍历当前巡检项列表
        // 2、筛选当前索引下的list巡检项，筛选出两者相等的数据，保存不相等的数据
        this.noConfigData.forEach((item) => {
          this.selectInspectionItemList[index].list =
                        this.selectInspectionItemList[index].list.filter((data) => {
                          return item.Id !== data.Id
                        })
        })
        // 统计选中的数量
        this.treeData = this.getSelectTotal(
          arr,
          this.queryForm.bind_id,
          this.selectInspectionItemList[index].list.length,
          false
        )
      }
    },
    selectAll (selection) {
      // console.log('selectAll---', selection);
      var arr = this.treeData.concat()
      var index = -1
      // 判断选中的巡检项列表是否有数据，若有，则获取当前节点所在索引位置
      if (this.selectInspectionItemList.length > 0) {
        index = this.selectInspectionItemList.indexOf(
          this.selectInspectionItemList.filter((item) => {
            return item.id === this.queryForm.bind_id
          })[0],
          0
        )
      }
      if (selection.length > 0) {
        // 全选
        if (index === -1) {
          // 没有找到索引，则将当前数据添加到选中巡检项中
          this.selectInspectionItemList.push({
            id: this.queryForm.bind_id,
            list: selection
          })
          // 统计选中的数量
          this.treeData = this.getSelectTotal(
            arr,
            this.queryForm.bind_id,
            selection.length,
            false
          )
        } else {
          // 若有索引，则
          // 1、遍历当前索引下的list巡检项，
          // 2、筛选出与list中id相等的数据，保存不相等的数据（若相等，则表示已存在，不添加；）
          // 3、将不相等的数据，添加到选中巡检项列表中
          this.selectInspectionItemList[index].list.forEach((item) => {
            // console.log(item);
            selection = selection.filter((data) => {
              return item.Id !== data.Id
            })
          })
          this.selectInspectionItemList[index].list.push(...selection)
          // 统计选中的数量
          this.treeData = this.getSelectTotal(
            arr,
            this.queryForm.bind_id,
            this.selectInspectionItemList[index].list.length,
            false
          )
        }
      } else {
        // 取消全选
        // 1、遍历当前巡检项列表
        // 2、筛选当前索引下的list巡检项，筛选出两者相等的数据，保存不相等的数据
        this.noConfigData.forEach((item) => {
          this.selectInspectionItemList[index].list =
                        this.selectInspectionItemList[index].list.filter((data) => {
                          return item.Id !== data.Id
                        })
        })
        // 统计选中的数量
        this.treeData = this.getSelectTotal(
          arr,
          this.queryForm.bind_id,
          this.selectInspectionItemList[index].list.length,
          false
        )
      }
    },
    // 统计选中的数量
    getSelectTotal (data, id, total, flag) {
      data.forEach((item) => {
        if (item.id === id) {
          // console.log(item.name + '-------------' + item.id);
          // console.log('-------------', flag);
          if (flag) {
            // 当存在巡检项时，统计数量
            item.selectTotal = item.selectTotal
              ? item.selectTotal + total
              : total
          } else {
            // 当表格选中项发生改变时，统计数量
            item.selectTotal = total
          }
        } else {
          if (item.children && item.children.length > 0) {
            this.getSelectTotal(item.children, id, total, flag)
          }
        }
      })
      return data
    },
    // 保存选中的巡检项
    Save () {
      console.log(this.selectInspectionItemList)
      this.addForm.list_inspection = []
      console.log(this.addForm.list_inspection)
      // 将所有选中的巡检项遍历添加到 巡检项配置列表中去
      this.selectInspectionItemList.forEach((item) => {
        this.addForm.list_inspection.push(...item.list)
      })
      console.log(this.addForm.list_inspection)
      // 清除对话框中所有的数据
      this.configDialogClose()
    },
    // 配置巡检项对话框关闭时触发
    configDialogClose () {
      this.isConfigDialog = false
      this.queryForm.bind_id = ''
      this.queryForm.inspection_name = ''
      this.treeData = []
      this.allSelectTotal = 0
      this.noConfigData = []
      this.selectInspectionItemList = []
      console.log('configDialogClose---------', this.selectInspectionItemList)
    },
    // 取消配置
    cancelConfig (row) {
      // console.log(row);
      this.addForm.list_inspection = this.addForm.list_inspection.filter(
        (item) => {
          return item.Id !== row.Id
        }
      )
      console.log(this.addForm.list_inspection)
    },
    // 序号
    indexMethod (index) {
      return index + 1
    },
    // 拖动排序
    setSort (row) {
      this.sortable = Sortable.create(this.el, {
        ghostClass: 'sortable-ghost',
        // ************* 拖动对象移动样式
        // dragClass: 'drag',
        // ************* 禁用html5原生拖拽行为
        // forceFallback: true,
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: (evt) => {
          // console.log(evt);
          const targetRow = this.addForm.list_inspection.splice(
            evt.oldIndex,
            1
          )[0]
          this.addForm.list_inspection.splice(evt.newIndex, 0, targetRow)
        }
      })
    },
    // 根据名称查找巡检项
    onSubminSearch () {
      var that = this
      axios.all([this.fnGetInspectionList()]).then(
        axios.spread(function (inspection) {
          // console.log('获取巡检项列表接口******', inspection);
          that.noConfigData =
                        inspection && inspection.Code === 200 ? inspection.Data : []
          // 判断选中的巡检项列表中是否有数据
          if (that.selectInspectionItemList.length > 0) {
            // 若存在数据
            // 1、遍历巡检项列表
            // 2、遍历选中的巡检项列表
            // 3、判断两者id是否相等，若相等，则选中
            that.noConfigData.forEach((item) => {
              that.selectInspectionItemList.forEach((data) => {
                if (data.id === that.queryForm.bind_id) {
                  data.list.forEach((li) => {
                    if (item.Id === li.Id) {
                      that.$nextTick(() => {
                        that.$refs.noConfigDataRef.toggleRowSelection(
                          item,
                          true
                        )
                      })
                    }
                  })
                }
              })
            })
          }
        })
      )
    },
    // 保存
    submitAddForm () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          var obj = {}
          var arr = []
          this.addForm.list_inspection.forEach((item, index) => {
            arr.push({
              inspection_id: item.Id,
              sort_number: index + 1
            })
          })
          obj.project_id = this.addForm.project_id
          obj.route_name = this.addForm.route_name
          obj.inspection_order = this.addForm.inspection_order
          obj.list_inspection = arr
          if (this.title === '新增路线') {
            this.fnAddInspectionRoute(obj)
          } else {
            obj.Id = this.id
            this.fnUpdateInspectionRoute(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增巡检路线请求
    async fnAddInspectionRoute (obj) {
      const res = await addInspectionRoute(obj)
      console.log('新增巡检路线请求----', res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑巡检路线请求
    async fnUpdateInspectionRoute (obj) {
      const res = await updateInspectionRoute(obj)
      console.log('编辑巡检路线请求----', res)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 点击查看，预览图片
    showPreviewPicture (pic) {
      this.previewPictureList = pic.split(',')
      this.isShowPreviewPicture = true
    },
    // 关闭预览图片
    onClosePreviewPicture () {
      this.isShowPreviewPicture = false
      this.previewPictureList = []
    }
  }
}
</script>

<style scoped lang="less">
.title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
}

.demo-ruleForm {

    .el-select,
    .el-input {
        width: 50%;
    }

    /deep/ .data_form_item {
        margin-left: 40px;
        margin-bottom: 10px;

        .el-form-item__content {
            margin-left: 0 !important;
        }
    }
}

.content_box {
    display: flex;

    .left_box {
        width: 30%;
    }

    .vertical_box {
        height: inherit;
        padding: 0 10px;

        .el-divider--vertical {
            height: 100%;
        }
    }

    .right_box {
        width: 65%;

        .hearder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            height: 32px;

            .el-input {
                height: inherit;
            }
        }
    }
}

.tablestyle {
    /deep/ .el-table__body-wrapper {
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
    }
}

.box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }
}
</style>
